
import { notify } from "@/core/helpers/globalJaya.ts";
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import DiagnosticsList from "@/components/diagnostic/DiagnosticsList.vue";
import { openLinkAgriBest } from "@/core/helpers/globalJaya";

export default defineComponent({
  name: "diagnostics",
  components: {
    CardTitleJaya,
    DiagnosticsList,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store
        .dispatch(Actions.FETCH_DIAGNOSTICS)
        .then(() => {
          store.dispatch(Actions.FETCH_EXPLOITATIONS).then(() => {
            store.dispatch(Actions.FETCH_QUIZ).then(() => {
              setCurrentPageTitle("Mes Diagnostics");
            });
          });
        })
        .catch(() => {
          router.push({ name: "homepage" });
          notify({
            text: "Une erreur est survenue. Veuillez réessayer ultérieurement",
            color: "error",
          });
        });
    });

    const openAgribestLink = (url) => {
      openLinkAgriBest(process.env.VUE_APP_ILLUSTRATED_URL + url);
    };

    const getDiagnostics = computed(() => store.getters.getDiagnostics);

    return {
      getDiagnostics,
      openAgribestLink,
    };
  },
});
